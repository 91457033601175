import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { inject } from "@angular/core";
import { AuthService } from "../auth.service";
import { tap } from "rxjs";

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);

  const redirectUrl = state.url;

  return authService.isAuthenticated().pipe(
    tap((authenticated) => {
      if (!authenticated) {
        router.navigate(["/auth/login"], {
          queryParams: { redirectTo: redirectUrl },
        });
      }
    }),
  );
};
