<p class="subtitle">
  {{
    platformName === platformType.team350Club.split("-")[0].trim()
      ? "The " + platformName
      : platformName
  }}
  joining process:
</p>
<div class="stepper">
  <span class="step" [ngClass]="pageNumber === 1 ? 'active-text' : 'step-text'">
    <span class="step-title">Step 1:</span>

    <span class="bold-step-description">
      Complete the application form (Part 1 of 2 -
      <span class="font-extralight"> Personal information</span> )
    </span>
  </span>
  <span class="step" [ngClass]="pageNumber === 2 ? 'active-text' : 'step-text'">
    <span class="step-title">Step 2:</span>
    <span class="bold-step-description">
      Complete the application form (Part 2 of 2 -
      <span class="font-extralight"> Role and diversity information</span
      >)</span
    >
  </span>
  <span class="step" [ngClass]="pageNumber === 3 ? 'active-text' : 'step-text'">
    <span class="step-title"> Step 3:</span>
    <span class="step-description">
      Wait for
      {{
        platformName === platformType.team350Club.split("-")[0].trim()
          ? "the " + platformName
          : platformName
      }}
      to review your application</span
    >
  </span>
  <span class="step step-text">
    <span class="step-title"> Step 4:</span>
    <span class="step-description">
      Receive confirmation of approval or reason for decline</span
    >
  </span>
</div>
